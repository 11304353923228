<template>
  <div class="pushNotice">
    <div class="navC chu600">绑定公众号
      <span class="ml50 chu500" style="color: #FF8201;">注：相关参数，请找负责公众号维护的相关技术人员获取！</span>
    </div>
    <!-- 表单内容 -->
    <div class="formBox">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
        <el-form-item label="公众号名称：" prop="title">
          <el-input v-model="ruleForm.title" style="width: 450px" clearable></el-input>
        </el-form-item>
        <el-form-item label="类型：" prop="type">
          <el-select v-model="ruleForm.type" placeholder="请选择">
            <el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Appid：" prop="appid">
          <el-input v-model="ruleForm.appid" style="width: 450px" clearable></el-input>
        </el-form-item>
        <el-form-item label="Appsecret：" prop="appsecret">
          <el-input v-model="ruleForm.appsecret" style="width: 450px" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="onCancel">取消</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')" class="saveBtn">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import * as accountApi from "@/api/teacherStudio/accountApi"
export default {
  data() {
    return {
      typeList: [
        { id: 1, name: "服务号" },
        { id: 2, name: "订阅号" },
        { id: 3, name: "企业号" },
      ],
      ruleForm: {
        title: "",
        type: "",
        appid: "",
        appsecret: "",
        studio_id: localStorage.getItem("studioId"),
      },
      rules: {
        title: [
          { required: true, message: "请输入公众号名称", trigger: "blur" },
        ],
        type: [
          { required: true, message: "请选择类型", trigger: "change" },
        ],
        appid: [
          { required: true, message: "请输入公众号Appid", trigger: "blur" },
        ],
        appsecret: [
          { required: true, message: "请输入公众号Appsecret", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    let rouName = this.$route.name
    this.$emit('rouname', rouName)
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          accountApi.addaccount(this.ruleForm).then(res => {
            this.onCancel()
          })
        } else {
          return false;
        }
      });
    },
    onCancel() {
      this.$router.go(-1)
    },
  },
};
</script>
<style lang="less" scoped>
.pushNotice {
  box-sizing: border-box;

  .navC {
    width: 100%;
    height: 60px;
    padding-left: 26px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border-bottom: 1px solid #f0f0f0;
  }

  .formBox {
    padding-top: 40px;
    box-sizing: border-box;
    padding-right: 20px;

    .upImg {
      margin-left: 10px;

      .avatar-uploader {
        .avatar {
          width: 120px;
          height: 120px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        .jh {
          width: 134px;
          height: 75px;
          background: #f7f7f7;
          border: 1px solid #ececec;
          border-radius: 4px;
          box-sizing: border-box;

          .avatar-uploader-icon {
            font-size: 30px;
            margin-top: 23px;
          }
        }
      }

      .disabled .el-upload--picture-card {
        display: none;
      }
    }

    .uploadData {
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;

      .childBox {
        width: 300px;
        height: 50px;
        background: #f9f9f9;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 20px;
        margin-bottom: 10px;

        .mBox {
          display: flex;
          align-items: center;

          .photo {
            width: 24px;
            height: 24px;
            margin-left: 12px;

            img {
              width: 24px;
              height: 24px;
            }
          }

          .title {
            margin-left: 10px;
            width: 153px;
            white-space: pre-line;
            overflow: hidden;
            -webkit-line-clamp: 1;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            cursor: pointer;

            span {
              font-size: 14px;
              font-weight: 400;
              text-align: left;
              color: #303133;
            }
          }
        }

        .rBox {
          margin-right: 20px;

          span {
            width: 7px;
            height: 7px;
            color: #9c9fa4;
            cursor: pointer;

            &:hover {
              color: #ff8201;
            }
          }
        }
      }
    }

    //保存
    .saveBtn {
      background: #ff8201;
      border: 1px solid #ff8201;
      border-radius: 6px;
    }
  }
}
</style>
